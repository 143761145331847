import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { reduced as navigation } from './navigation';
import { reduced as playground } from '../features/playground';
import { reduced as tooltips } from '../components/overlays/tooltip';

export const store = configureStore({
    reducer: {
        ...navigation,
        ...playground,
        ...tooltips,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
