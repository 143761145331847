import React, { useEffect, useRef } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IRouteProps } from 'typings';
import { Handler as PlaygroundHandler } from './features/playground';
import { Layout } from 'features/layout';
import { clearAllTooltips } from 'components/overlays/tooltip/slice';

const getComponent = (controller: string) => {
    switch (controller) {
        case undefined: {
            return null;
        }
        case 'playground': {
            return <PlaygroundHandler />;
        }
        default: {
            return <h1>404.</h1>;
        }
    }
};

const Component = (props: RouteComponentProps) => {
    const referrer = useRef<string>();
    const pathname = props.location?.pathname || '';
    const paths = pathname.split('/').filter(Boolean);
    const controller = paths?.[0];

    const path: IRouteProps['path'] = {
        paths,
        pathname,
        controller,
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearAllTooltips());

        return () => {
            referrer.current = window.location.href;
        };
    }, [dispatch, path]);

    const component = getComponent(controller);

    return <Layout path={path}>{component}</Layout>;
};

export const Routes = () => (
    <Switch>
        <Route
            path="*"
            exact
            component={(props: RouteComponentProps) => <Component {...props} />}
        />
    </Switch>
);
