import React from 'react';

export type CustumIconKey =
    | 'mail'
    | 'linkedin'
    | 'twitter'
    | 'flickr'
    | 'background';

const viewBox = '0 0 180 180';

const paths: Record<CustumIconKey, { Tag: string; [key: string]: string }[]> = {
    background: [
        {
            Tag: 'path',
            d:
                'M180,90c0,32.79-57.21,90-90,90S0,122.79,0,90,57.18,0,90,0,180,57.18,180,90Z',
        },
    ],
    flickr: [
        {
            Tag: 'circle',
            cx: '124',
            cy: '90',
            r: '28',
        },
        {
            Tag: 'circle',
            cx: '56',
            cy: '90',
            r: '28',
        },
    ],
    twitter: [
        {
            Tag: 'path',
            d:
                'M133.41,70.11c.07,1,.07,2,.07,3,0,30.7-23.37,66.1-66.09,66.1v0a65.81,65.81,0,0,1-35.61-10.42,48.71,48.71,0,0,0,5.53.34,46.66,46.66,0,0,0,28.85-10A23.25,23.25,0,0,1,44.46,103a23.05,23.05,0,0,0,10.48-.41A23.22,23.22,0,0,1,36.31,79.85v-.3a23,23,0,0,0,10.54,2.91,23.27,23.27,0,0,1-7.19-31A65.91,65.91,0,0,0,87.54,75.71a23.25,23.25,0,0,1,39.58-21.18,46.81,46.81,0,0,0,14.75-5.64,23.29,23.29,0,0,1-10.21,12.84A46.13,46.13,0,0,0,145,58.08,47.35,47.35,0,0,1,133.41,70.11Z',
        },
    ],
    linkedin: [
        {
            Tag: 'path',
            d:
                'M62.67,138.5V70.64H40.11V138.5ZM51.39,61.38c7.87,0,12.76-5.22,12.76-11.73C64,43,59.26,37.93,51.54,37.93S38.78,43,38.78,49.65s4.89,11.73,12.46,11.73ZM75.15,138.5H97.7V100.61a15.29,15.29,0,0,1,.75-5.5c1.63-4.06,5.34-8.26,11.57-8.26,8.16,0,11.43,6.23,11.43,15.35v36.3H144V99.59c0-20.84-11.13-30.54-26-30.54-12.16,0-17.51,6.8-20.47,11.43h.15V70.64H75.15c.3,6.37,0,67.86,0,67.86Z',
        },
    ],
    mail: [
        {
            Tag: 'path',
            d:
                'M141,69v48a6.28,6.28,0,0,1-6.28,6.28H45.61A6.28,6.28,0,0,1,39.33,117V69l44.13,31.43a0,0,0,0,0,0,0,10.51,10.51,0,0,0,1.23.87,0,0,0,0,0,0,0,10.49,10.49,0,0,0,10.91,0,.47.47,0,0,0,.09-.06,9.11,9.11,0,0,0,1.08-.76l.07-.06Z',
        },
        {
            Tag: 'path',
            d:
                'M141,63.4V69L96.86,100.48l-.07.06-1.08.76a.47.47,0,0,1-.09.06,10.49,10.49,0,0,1-10.91,0,0,0,0,0,1,0,0l-1.23-.87a0,0,0,0,1,0,0L39.33,69V63.4a6.28,6.28,0,0,1,6.28-6.28h89.11A6.28,6.28,0,0,1,141,63.4Z',
        },
    ],
};

interface ICustomIcon {
    icon: CustumIconKey;
    className?: string;
}

export const CustomIcon = ({ icon, className }: ICustomIcon) => {
    const path = paths[icon];

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            className={className}
        >
            <g>
                {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                {path.map(({ Tag, ...restProps }, index) => (
                    <Tag key={index} {...restProps} />
                ))}
            </g>
        </svg>
    );
};
