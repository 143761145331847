import { Component } from 'react';
import { IChildren } from 'typings';

interface IErrorBoundary extends IChildren {}

interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<IChildren, IErrorBoundaryState> {
    constructor(props: IErrorBoundary) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): IErrorBoundaryState {
        // Update state so the next render will show the fallback UI.

        console.log('getDerivedStateFromError', error);

        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: any): void {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);

        console.log('componentDidCatch', error, errorInfo);
    }

    render(): IChildren['children'] {
        /*if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }*/

        return this.props.children;
    }
}
