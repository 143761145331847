import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getNavigationState } from 'store/navigation';
import navigationStyles from 'components/navigation/navigation/navigation.module.scss';
//import styles from 'styles/index.scss';

export const LayoutHelper: FunctionComponent = () => {
    const { isNavigationOpen } = useSelector(getNavigationState);

    useEffect(() => {
        if (isNavigationOpen) {
            document.body.classList.add(navigationStyles.open);
        }

        return () => document.body.classList.remove(navigationStyles.open);
    }, [isNavigationOpen]);

    return <></>;
};
