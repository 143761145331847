import React from 'react';
import { styles } from './styles';
import { useCurrentRecipe } from './hooks';
import { Placement } from './placement';

export function Playground() {
    //const recipe = useCurrentRecipe();

    return <Placement />;
}
