import React from 'react';
import { Playground } from './playground';
import { useLoadRecipe, loadedState, loadingState } from './hooks';

export function Handler() {
    const recipeState = useLoadRecipe();
    
    return <Playground />;

    if (recipeState === loadingState) return <>Loading</>;
    if (recipeState === loadedState) return <Playground />;

    return <>Something...</>;
}
