import { ReactNode, useRef, useEffect, useCallback, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { debounce, generateId, getElementRect } from 'utils';
import { hover, remove } from './slice';

export interface IUseTooltip {
    (props: {
        ref: RefObject<HTMLElement>;
        title: ReactNode;
        placement?: 'left' | 'right' | 'top' | 'bottom';
        //trigger?: ('click' | 'hover' | Function)[];
        delay?: number;
    }): { show: () => void; hide: () => void };
}

export const useTooltip: IUseTooltip = ({
    ref,
    title,
    placement = 'top',
    delay = 50,
}) => {
    const id = useRef<string>('');
    const dispatch = useDispatch();

    useEffect(() => {
        id.current = generateId();

        return () => {
            dispatch(remove(id.current));
        };
    }, []);

    const show = useCallback(
        debounce(() => {
            if (ref.current) {
                const data = {
                    id: id.current,
                    position: getElementRect(ref.current),
                    content: title,
                    placement,
                };

                dispatch(hover(data, delay));
            }
        }, 200),
        [id.current, ref.current]
    );

    const hide = useCallback(
        debounce(() => dispatch(remove(id.current)), 200),
        [id.current]
    );

    useEffect(() => {
        if (!ref.current) return;

        ref.current.addEventListener('mouseenter', show);
        ref.current.addEventListener('mouseleave', hide);

        return () => {
            if (!ref.current) return;

            ref.current.removeEventListener('mouseenter', show);
            ref.current.removeEventListener('mouseleave', hide);
        };
    }, [ref.current, show, hide]);

    return { show, hide };
};
