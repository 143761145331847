import React from 'react';
import { isPartiallyWithin, rectToRectPoints } from 'utils/math/is-within';
import { Coordinates, Rect, RectPoints } from 'utils/math/typings';
import { styles } from '../styles';

const spacing = 1;

export const Placement = () => {
    const width = 10;
    const array = [0, 1, 2, 3, 88, 89, 80, 89, 100 - width];

    const total = array.length * (width + spacing);
    const span = Math.max(...array) + width - Math.min(...array);

    if (total > span) return null;

    const newPlacement = findPlacement2(
        array.map((left): Rect => ({ left, top: 0, width, height: 0 }))
    );

    console.log(
        array,
        newPlacement.map(({ left }) => left)
    );

    return (
        <div>
            <div className={styles.container}>
                {array.map((placement) => (
                    <div
                        key={placement}
                        className={styles.item}
                        style={{ width, left: placement }}
                    />
                ))}
            </div>
            <div className={styles.container}>
                {newPlacement.map((placement) => (
                    <div
                        key={placement.left}
                        className={styles.item}
                        style={{ width, left: placement.left }}
                    />
                ))}
            </div>
        </div>
    );
};

const getCenter = (rect: Rect): Coordinates => {
    return { x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 };
};

const checkIfOverlapping = (firstRect: Rect, secondRect: Rect): boolean => {
    return isPartiallyWithin(
        rectToRectPoints(firstRect),
        rectToRectPoints(secondRect)
    );
};

const squaredEuclideanLength = (points: Coordinates | RectPoints): number => {
    if ((points as RectPoints).x1 !== undefined) {
        const { x1, y1, x2, y2 } = points as RectPoints;

        return Math.pow(x1 - y1, 2) + Math.pow(x2 - y2, 2);
    }

    const { x, y } = points as Coordinates;

    return Math.pow(x - y, 2);
};

const euclideanLength = (points: Coordinates | RectPoints) => {
    return Math.sqrt(squaredEuclideanLength(points));
};

const normalizeCoordinates = ({ x, y }: Coordinates): Coordinates => {
    // /   x       y       z   \
    // | ----- , ----- , ----- |
    // \ x+y+z   x+y+z   x+y+z /

    const sum = x + y;

    return {
        x: x / sum,
        y: y / sum,
    };
};

const scaleCoordinates = (
    { x, y }: Coordinates,
    scale: number
): Coordinates => {
    return {
        x: x * scale,
        y: y * scale,
    };
};

function findPlacement(list: Rect[]) {
    let isSeparated = false;
    let counter = 0;

    do {
        counter++;
        isSeparated = true;

        console.log('jk');

        for (let i = 0; i < list.length; i++) {
            const room = list[i];

            // reset for iteration
            let velocity: Coordinates | null = null;
            const center: Coordinates = getCenter(room);

            for (let j = 0; j < list.length; j++) {
                const otherRoom = list[j];

                if (i === j) continue;

                if (!checkIfOverlapping(room, otherRoom)) continue;

                const otherCenter: Coordinates = getCenter(otherRoom);

                const diff: Coordinates = {
                    x: center.x - otherCenter.x,
                    y: center.y - otherCenter.y,
                };

                const diffLen2: number = squaredEuclideanLength(diff);

                if (diffLen2 > 0) {
                    const repelDecayCoefficient = 1.0;
                    const scale: number = repelDecayCoefficient / diffLen2;

                    const normalized = normalizeCoordinates(diff);
                    const scaled = scaleCoordinates(normalized, scale);

                    velocity = scaled;
                }
            }

            if (velocity && squaredEuclideanLength(velocity) > 0) {
                isSeparated = false;

                console.log(velocity);

                const delta = 3; // 20

                const normalizes = normalizeCoordinates(velocity);
                const scaled = scaleCoordinates(normalizes, delta);

                room.left += scaled.x;
                room.top += scaled.y;
            }
        }
    } while (!isSeparated && counter < 10);

    return list;
}

function findPlacement2(list: Rect[]) {
    let isSeparated = false;
    let counter = 0;

    do {
        counter++;

        const overlappingValues = list
            .map((item, index) => {
                const overlappingCount = list.filter(
                    (innerItem, innerIndex) =>
                        index !== innerIndex &&
                        checkIfOverlapping(item, innerItem)
                ).length;

                if (!overlappingCount) return;

                return {
                    index,
                    left: item.left,
                    overlappingCount: list.filter(
                        (innerItem, innerIndex) =>
                            index !== innerIndex &&
                            checkIfOverlapping(item, innerItem)
                    ).length,
                };
            })
            .filter(Boolean) as {
            index: number;
            left: number;
            overlappingCount: number;
        }[];

        if (!overlappingValues.length) {
            isSeparated = true;
            break;
        }

        overlappingValues.sort((a, b) => (a.left < b.left ? -1 : 1));

        console.log(overlappingValues);

        const moveLeft = (item: Rect, { left }: Rect) => {
            item.left = left - item.width - spacing;
        };

        const moveRight = (item: Rect, { left, width }: Rect) => {
            item.left = left + width + spacing;
        };

        moveLeft(
            list[overlappingValues[0].index],
            list[overlappingValues[1].index]
        );

        if (overlappingValues.length > 2) {
            const lastIndex = overlappingValues.length - 1;

            moveRight(
                list[overlappingValues[lastIndex].index],
                list[overlappingValues[lastIndex - 1].index]
            );
        }
    } while (!isSeparated && counter < 10);

    /*overlappingValues.forEach((item) => {
        if (!item.overlappingCount) return;

        console.log(item);
    });*/

    /*do {
        counter++;
        isSeparated = true;

        console.log('jk');

        for (let i = 0; i < list.length; i++) {
            const room = list[i];

            // reset for iteration
            let velocity: Coordinates | null = null;
            const center: Coordinates = getCenter(room);

            for (let j = 0; j < list.length; j++) {
                const otherRoom = list[j];

                if (i === j) continue;

                if (!checkIfOverlapping(room, otherRoom)) continue;

                const otherCenter: Coordinates = getCenter(otherRoom);

                const diff: Coordinates = {
                    x: center.x - otherCenter.x,
                    y: center.y - otherCenter.y,
                };

                const diffLen2: number = squaredEuclideanLength(diff);

                if (diffLen2 > 0) {
                    const repelDecayCoefficient = 1.0;
                    const scale: number = repelDecayCoefficient / diffLen2;

                    const normalized = normalizeCoordinates(diff);
                    const scaled = scaleCoordinates(normalized, scale);

                    velocity = scaled;
                }
            }

            if (velocity && squaredEuclideanLength(velocity) > 0) {
                isSeparated = false;

                console.log(velocity);

                const delta = 3; // 20

                const normalizes = normalizeCoordinates(velocity);
                const scaled = scaleCoordinates(normalizes, delta);

                room.left += scaled.x;
                room.top += scaled.y;
            }
        }
    } while (!isSeparated && counter < 10);*/

    return list;
}
