import React, { useRef } from 'react';
import { IChildren, IRouteProps } from 'typings';
import { Link } from 'react-router-dom';
import styles from './layout.module.scss';
import { Icon } from 'components/images-icons/icon';
import { useTooltip } from 'components/overlays/tooltip';

interface IWrapper extends IChildren, IRouteProps {
    fullPage?: boolean;
}

export const Wrapper = ({ children, path: { controller } }: IWrapper) => {
    const mailRef = useRef<HTMLAnchorElement>(null);
    const twitterRef = useRef<HTMLAnchorElement>(null);
    const linkedInRef = useRef<HTMLAnchorElement>(null);
    const flickrRef = useRef<HTMLAnchorElement>(null);
    const spisBilligtLinkRef = useRef<HTMLAnchorElement>(null);
    const playgroundRef = useRef<HTMLAnchorElement>(null);

    useTooltip({
        ref: mailRef,
        title: 'Email',
        placement: 'left',
    });

    useTooltip({
        ref: twitterRef,
        title: 'Twitter',
        placement: 'left',
    });

    useTooltip({
        ref: linkedInRef,
        title: 'LinkedIn',
        placement: 'left',
    });

    useTooltip({
        ref: flickrRef,
        title: 'Flickr',
        placement: 'left',
    });

    useTooltip({
        ref: spisBilligtLinkRef,
        title: 'Collection of Danish recipes',
        placement: 'top',
    });

    useTooltip({
        ref: playgroundRef,
        title: 'TBD',
        placement: 'top',
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.socialWrapper}>
                <a
                    ref={mailRef}
                    href="mailto:mail@camillahorne.dk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialIcon}
                >
                    <Icon icon="background" className={styles.backgroundIcon} />
                    <Icon icon="mail" className={styles.icon} />
                </a>
                <a
                    ref={twitterRef}
                    href="https://twitter.com/CamillaHorne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialIcon}
                >
                    <Icon
                        icon="background"
                        className={`${styles.backgroundIcon} ${styles.twitter}`}
                    />
                    <Icon icon="twitter" className={styles.icon} />
                </a>
                <a
                    ref={linkedInRef}
                    href="https://linkedin.com/in/CamillaHorne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialIcon}
                >
                    <Icon
                        icon="background"
                        className={`${styles.backgroundIcon} ${styles.linkedin}`}
                    />
                    <Icon icon="linkedin" className={styles.icon} />
                </a>
                <a
                    ref={flickrRef}
                    href="https://www.flickr.com/photos/CamillaHorne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialIcon}
                >
                    <Icon
                        icon="background"
                        className={`${styles.backgroundIcon} ${styles.flickr}`}
                    />
                    <Icon icon="flickr" className={styles.icon} />
                </a>
            </div>
            <div className={styles.top}>
                <div>
                    {controller ? (
                        <Link to="/">
                            <b className={styles.websiteName}>Camilla Horne</b>
                        </Link>
                    ) : (
                        <b className={styles.websiteName}>Camilla Horne</b>
                    )}
                    {/*!!controller && (
                        <>
                            <span className={styles.dotSeparater}>&bull;</span>
                            <i className={styles.contentName}>{controller}</i>
                        </>
                    )*/}
                </div>
                <div>
                    {/*<Link
                        ref={playgroundRef}
                        to="/playground"
                        className={`${styles.projectLink} ${
                            controller === 'playground' ? styles.activeLink : ''
                        }`}
                    >
                        Playground
                    </Link>
                    <span className={styles.dotSeparater}>&bull;</span>*/}
                    <a
                        ref={spisBilligtLinkRef}
                        href="https://spisbilligt.dk"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.projectLink}
                    >
                        Spis Billigt
                    </a>
                </div>
            </div>
            <div id="content" className={styles.content}>
                <div className={styles.innerContent}>{children}</div>
            </div>
            <div className={styles.bottom} />
        </div>
    );
};
